import { createGlobalStyle } from 'styled-components/macro'

const Styles = createGlobalStyle`
  :root {
    font-size: 100%;
    line-height: 1.5;
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: ${props => props.theme.fontSizes.root};
    scroll-behavior: smooth;
    color: ${props => props.theme.colors.black};
    background: black;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: ${props => props.theme.colors.light};
    font-family: ${props => props.theme.fonts[0]};
    font-size: ${props => props.theme.fontSizes.root};
    color: ${props => props.theme.colors.dark};
  }

  ::placeholder {
    opacity: 1;
  }

  svg {
    width: auto;
    /* color: ${props => props.theme.colors.primary}; */
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 0.2rem solid transparent;

    &:hover,
    &:focus,
    &:focus-within {
      text-decoration: none;
      border-image: linear-gradient(
        to right,
        #b827fc 0%,
        #2c90fc 25%,
        #b8fd33 50%,
        #fec837 75%,
        #fd1892 100%
      );
      border-image-slice: 1;
      border-bottom-width: 0.2rem;
    }
    &:focus-visible{
      outline: ${props => props.theme.border.link} solid ${props => props.theme.colors.primary};
      border-radius: ${props => props.theme.borderRadius.link};
    }
  }

  button {
    color: ${props => props.theme.colors.black};
    background-color: #b827fc;
    color: white;
    padding: 1rem;
    border: 0;
    border-radius: 1rem;
    cursor: pointer;
    font-weight: 800;

    &:hover,
    &:focus,
    &:focus-within {
      filter: brightness(1.1);
    }
  }

  .cc-window {
    box-shadow: ${props => props.theme.boxShadow.current};
    font-family: ${props => props.theme.fonts[0]};
    font-size: ${props => props.theme.fontSizes.m};
    font-weight: 600;

    .cc-link {
      padding: 0;
      border-bottom: 0.2rem solid transparent;
      text-decoration: none;
    }

    .cc-btn {
      border: 0;
    }
  }
`

export default function GlobalStyles() {
  return <Styles />
}
