import GlobalStyles from './GlobalStyles'
import useInternationalization from './useInternationalization'
import Router from './Router'
import CookiesConsent from './CookiesConsent'
import Header from './layout/Header'
import Footer from './layout/Footer'

function App() {
  const { internationalizationInit } = useInternationalization()

  return (
    <>
      <GlobalStyles />

      {internationalizationInit && (
        <>
          <Header />
          <Router />
          <CookiesConsent />
          <Footer />
        </>
      )}
    </>
  )
}

export default App
