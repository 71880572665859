import { useEffect } from 'react'
import { useTheme } from 'styled-components/macro'

declare global {
  interface Window {
    cookieconsent: {
      initialise: (opts: Record<string, unknown>) => void
    }
  }
}

export default function CookiesConsent() {
  const theme = useTheme()

  useEffect(() => {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: theme.colors.white,
          text: theme.colors.secondary,
        },
        highlight: {
          background: theme.colors.light,
          text: theme.colors.primary,
        },
        button: {
          background: theme.colors.primary,
          text: theme.colors.white,
        },
      },
      theme: 'classic',
      position: 'bottom-left',
      content: {
        message: 'Ce site utilise des cookies qui assurent son bon fonctionnement.',
        dismiss: 'Compris',
        deny: 'Décliner',
        link: `En savoir plus 🔗`,
        header: 'Cookies utilisés sur le site.',
        allow: 'Accepter les cookies',
        href: 'https://www.cookiesandyou.com',
        close: '&#x274c;',
        policy: 'Politique de cookie',
        target: '_blank',
      },
    })
  }, [
    theme.colors.black,
    theme.colors.light,
    theme.colors.primary,
    theme.colors.secondary,
    theme.colors.white,
  ])

  return null
}
