import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 1rem;
  padding: 2rem;
  padding-top: 7rem;
  justify-content: center;
  align-items: center;
`
const FlexCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 33rem;
  width: 100%;
`

function Page404() {
  const navigate = useNavigate()

  const navigateToHome = () => {
    navigate('/')
  }

  return (
    <Container>
      <FlexCenter>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="auto"
          height="30vh"
          fill="#000000"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none" />
          <circle cx="128" cy="128" r="96" opacity="0.2" />
          <circle
            cx="128"
            cy="128"
            r="96"
            fill="none"
            stroke="#000000"
            strokeMiterlimit="10"
            strokeWidth="16"
          />
          <line
            x1="184"
            y1="96"
            x2="152"
            y2="128"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
          <line
            x1="184"
            y1="128"
            x2="152"
            y2="96"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
          <line
            x1="104"
            y1="96"
            x2="72"
            y2="128"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
          <line
            x1="104"
            y1="128"
            x2="72"
            y2="96"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
          <circle cx="128" cy="180" r="12" />
        </svg>
        <h1 style={{ textAlign: 'center' }}>Page non trouvée</h1>
        <button type="button" onClick={navigateToHome}>
          Retour à l&apos;accueil
        </button>
      </FlexCenter>
    </Container>
  )
}

export default Page404
