const theme = {
  colors: {
    primary: '#b827fc', // primary violet
    secondary: '#2F2E41', // secondary deep blue
    light: '#F5F5F5', // light gray
    dark: '#2B2B2B', // stone black
    grey: '#888888', // subtle grey
    white: '#FFFFFF',
    black: '#000000',
    blue: '#3f3d56',
    lightGrey: '#CCCCCC', // light grey
    disabled: '#CCCCCC',
    error: '#dc3545',
    success: '#00C7A3',
    warn: '#fcd000',
    // colorimetry
    compound: ['#19AAFA', '#274C61', '#00C7A3', '#CE4733', '#FA1926'],
    analogous: ['#0B8CD4', '#0BCADE', '#00C7A3', '#0BDE7C', '#0BD43D'],
    monochromatic: [],
    complemantary: [],
    doubleSplitComplementary: [],
    shades: [],
    // specific palette
    pastel: ['#0BCADE', '#274C61', '#00C7A3', '#CE4733', '#0BDE7C'],
  },
  fonts: ["'Manrope', sans-serif", "'Plus Jakarta Sans', sans-serif"],
  fontSizes: {
    root: '1.2rem',
    s: '0.613rem', // 13px
    sm: '0.7rem',
    m: '0.8rem', // 16px
    l: '1.3rem', // 24px
    xl: '2.3rem', // 40px
  },
  lineHeight: {
    s: '1rem',
    sm: '1.2rem',
    m: '1.5rem',
    l: '2rem',
    xl: '3rem',
    // fontSizes index key
    '0.813rem': '1rem',
    '0.9rem': '1.2rem',
    '1rem': '1.5rem',
    '1.5rem': '2rem',
    '2.875rem': '3rem',
  },
  borderRadius: {
    current: '0.625rem', // 10px
    input: '0.5rem', // 8px
    button: '0.5rem', // 8px
    link: '0.188rem', // 3px
    checkbox: '0.188rem', // 3px
  },
  boxShadow: {
    current: '0 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.05)',
    card: '0 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.25)',
    popup: '1px 2px 18px rgba(0,0,0,0.25)',
  },
  border: {
    button: '0.125rem', // 2px
    input: '0.125rem', // 2px
    link: '0.125rem', // 2px
  },
  padding: {
    button: '0.8rem 1.563rem', // 5px
    icon: '0.313rem', // 5px
    input: '0.8rem 0.625rem', // 10px
    select: '0.245rem 0', // 10px
  },
  sizes: {
    flexSection: '37.5rem',
  },
}

export default theme
