import { useMediaQuery } from 'react-responsive'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import TerminalIcon from '@mui/icons-material/Terminal'
import Diversity3Icon from '@mui/icons-material/Diversity3'

import { TitleBackground, Section, Inline, Card } from '../../layout/Elements'

export default function Services() {
  const isLargeScreen = useMediaQuery({ minWidth: 1100 })
  const isMobile = useMediaQuery({ maxWidth: 650 })

  return (
    <Section
      darkMode
      isLargeScreen={isLargeScreen}
      isMobile={isMobile}
      style={{
        backgroundColor: '#101010',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <h1 id="services">Services</h1>
      <TitleBackground darkMode>Services</TitleBackground>

      <Card style={{ marginRight: isLargeScreen ? '20rem' : 0 }}>
        <Inline>
          <DesignServicesIcon fontSize="large" style={{ color: '#b827fc' }} />
          <h2 style={{ color: '#b827fc' }}>Concrétisation Rapide de Vos Idées</h2>
        </Inline>
        <br />
        Vous avez une idée brillante, mais comment la matérialiser ? En utilisant la méthodologie du{' '}
        <strong>Design Sprint</strong>, je transforme vos concepts en maquettes testées et
        approuvées en 5 jours. Non seulement ces{' '}
        <strong>prototypes donneront vie à vos idées</strong>, mais ils seront également vos
        meilleurs alliés pour fédérer vos équipes, engager vos utilisateurs et convaincre les
        investisseurs.
      </Card>
      {/* <Circle right style={{ top: isMobile ? '30%' : '35%', left: '13%' }} /> */}
      <Card>
        <Inline>
          <TerminalIcon fontSize="large" style={{ color: '#2c90fc' }} />
          <h2 style={{ color: '#2c90fc' }}>Expertise en Développement Web / Mobile</h2>
        </Inline>
        <br />
        Avec <strong>7 ans d&apos;expérience en Javascript</strong> et Node JS, je maîtrise les
        bonnes pratiques du développement web. En utilisant des technologies standardisées et
        populaires comme <strong>ReactJS et Typescript</strong>, je garantie un code robuste,
        sécurisé, et facilement maintenable, adossé à des normes reconnues comme le AirBnB Style
        Guide.
      </Card>
      {/* <Circle style={{ top: isMobile ? '60%' : '65%', right: '13%' }} /> */}
      <Card style={{ marginLeft: isLargeScreen ? '20rem' : 0 }}>
        <Inline>
          <Diversity3Icon fontSize="large" style={{ color: '#fd1892' }} />
          <h2 style={{ color: '#fd1892' }}>Pilotage Agile et Garantie de Qualité</h2>
        </Inline>
        <br />
        L&apos;agilité est au cœur de ma démarche. Ancien Scrum Master, je structure vos projets en{' '}
        <strong>sprints réguliers de 2 à 4 semaines</strong>, garantissant une visibilité claire et
        une bonne qualité grâce à des processus rigoureux tels que la revue de code et les tests. Je
        fais le pont entre les{' '}
        <strong>impératifs business et techniques pour assurer le succès</strong> de votre projet.
      </Card>
    </Section>
  )
}
