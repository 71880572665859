// Polyfills
import 'react-app-polyfill/stable'

import { StrictMode } from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'

import './i18n'

import './index.css'
import theme from './theme'
import { StoreProvider } from './context/store'
import App from './app/App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <StoreProvider>
          <App />
        </StoreProvider>
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
