import styled from 'styled-components/macro'
import { useMediaQuery } from 'react-responsive'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Menu from '@mui/material/Menu'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'

import { ReactComponent as Logo } from '../../assets/logo.svg'

const Dot = styled.div<{ color: string }>`
  content: \f111;
  background-color: ${props => props.color};
  border-radius: 50%;
  min-width: 0.5rem;
  min-height: 0.5rem;
  max-width: 0.5rem;
  max-height: 0.5rem;
`
const Background = styled.header<{ isTablet: boolean; isMobile: boolean }>`
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  padding: ${props =>
    props.isMobile
      ? '1.4rem 2rem 1.2rem'
      : props.isTablet
      ? '1.5rem 4rem 1.3rem'
      : '1.6rem 8rem 1.4rem'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${props => (props.isMobile ? '1rem' : '2rem')};

  transition: padding 0.4s;
`
const Title = styled.a`
  font-size: 2rem;
  font-family: ${props => props.theme.fonts[0]};
  font-weight: 800;
  color: white;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  &:hover,
  &:focus,
  &:focus-within {
    border-image: none;
  }
`
const MenuLink = styled.a`
  font-size: ${props => props.theme.fontSizes.m};
  font-family: ${props => props.theme.fonts[0]};
  font-weight: 650;
  color: #ffffff;
`
const FlexEnd = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${props => (props.isMobile ? '0.3rem' : '1rem')};

  ${MenuLink} {
    ${props => props.isMobile && `font-size: ${props.theme.fontSizes.s}`};
  }
`
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
`
const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 1rem;
  }
`
const CTAMenuLink = styled(MenuLink)`
  border-image: linear-gradient(
    to left,
    #b827fc 0%,
    #2c90fc 25%,
    #b8fd33 50%,
    #fec837 75%,
    #fd1892 100%
  );
  border-image-slice: 1;
  border-bottom-width: 0.2rem;
`

function MenuList({ isMobile }: { isMobile: boolean }) {
  return (
    <>
      <MenuLink tabIndex={0} href="#services">
        SERVICES
      </MenuLink>
      {isMobile ? null : <Dot color="#fd1892" />}
      <MenuLink tabIndex={0} href="#realisations">
        RÉALISATIONS
      </MenuLink>
      {isMobile ? null : <Dot color="#fd1892" />}
      <MenuLink tabIndex={0} href="#propos">
        À PROPOS
      </MenuLink>
      {isMobile ? null : <Dot color="#fd1892" />}
      <MenuLink tabIndex={0} href="#contact">
        CONTACT
      </MenuLink>
    </>
  )
}

function MobileMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {open ? <MenuOpenIcon htmlColor="#ffffff" /> : <MenuIcon htmlColor="#ffffff" />}
      </IconButton>
      <StyledMenu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <FlexCol>
          <MenuList isMobile />
        </FlexCol>
      </StyledMenu>
    </div>
  )
}

function Header() {
  const isLargeScreen = useMediaQuery({ minWidth: 1190 })
  const isMobile = useMediaQuery({ maxWidth: 790 })
  const [isScrolled, setScroll] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const handleScroll = () => {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <Background
      id="header"
      isMobile={isMobile}
      isTablet={!isLargeScreen}
      style={isScrolled ? { paddingTop: '0.3rem', paddingBottom: '0.3rem' } : {}}
    >
      <Title href={location.pathname === '/' ? '#top' : '/'}>
        <Logo
          height={isMobile ? '1.5rem' : '2.5rem'}
          width="auto"
          style={{ justifySelf: 'flex-start' }}
        />
        {isLargeScreen && 'esign Hor|zon'}
      </Title>

      <FlexEnd style={isMobile ? { flexDirection: 'row-reverse' } : undefined}>
        {isMobile ? <MobileMenu /> : <MenuList isMobile={false} />}
        <Dot color="#fd1892" />
        <CTAMenuLink
          tabIndex={0}
          target="_blank"
          href="https://www.malt.fr/profile/quentinfremeaux"
          title="Voir mes offres sur Malt"
        >
          M&apos;EMBAUCHER
        </CTAMenuLink>
      </FlexEnd>
    </Background>
  )
}

export default Header
