import { Routes, Route } from 'react-router-dom'

import Landing from './pages/Landing'
import Page404 from './pages/404/Page404'
import MentionsLegales from './pages/Mentions/MentionsLegales'

function Router() {
  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Landing />} />
      <Route path="/mentions-legales" element={<MentionsLegales />} />

      {/* Unauthorized pages */}
      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}

export default Router
