/* eslint-disable @typescript-eslint/no-floating-promises */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { resources } from './locales/resources'
import { Lang } from './types'

const availableLng = Object.keys(resources) as Array<Lang>
const availableNs = Object.keys(resources[availableLng[0]]) as Array<
  keyof typeof resources[typeof availableLng[0]]
>

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // detects user language in the browser
  .init({
    resources,
    ns: availableNs,
    fallbackLng: availableLng[0],
    returnEmptyString: false,
    returnNull: false,
    keySeparator: false,
    nsSeparator: false,
    pluralSeparator: '___',
    contextSeparator: '___',
    defaultNS: 'translation',
    debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',

    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: true,
      transKeepBasicHtmlNodesFor: ['strong', 'i'],
    },
  })

export default i18n
