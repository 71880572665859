import Stats from 'stats.js'

function getRandom(min: number, max: number) {
  return Math.random() * (max - min) + min
}

function hex2rgba(hex: string, alpha = 1): string {
  let r = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i)
  let c = null
  if (r) {
    c = r.slice(1, 4).map(x => parseInt(x, 16))
  }
  r = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i)
  if (r) {
    c = r.slice(1, 4).map(x => 0x11 * parseInt(x, 16))
  }
  if (!c) {
    return ''
  }
  return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${alpha})`
}

function animateScene() {
  window.requestAnimationFrame =
    window.requestAnimationFrame ||
    // @ts-expect-error not exists
    window.mozRequestAnimationFrame ||
    // @ts-expect-error not exists
    window.webkitRequestAnimationFrame

  const canvas = document.querySelector<HTMLCanvasElement>('#backgroundCanvasColors')!
  if (!canvas) throw Error('Unexpected Error - Canvas not retrieved!')

  canvas.width = 854
  canvas.height = 480

  const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!
  if (!ctx) throw Error('Unexpected Error - Canvas context not created!')

  ctx.globalCompositeOperation = 'saturation'

  const particles: Array<Particle> = []

  let pIndex = 0

  const stats = new Stats()
  stats.showPanel(0)
  // if (process.env.NODE_ENV !== 'production') document.body.appendChild(stats.dom)

  class Particle {
    color: string

    x: number

    y: number

    posX: number

    posY: number

    circleSize: number

    direction: number

    wave: number | undefined

    amplitude: number

    waveSpeed: number

    speed: number

    id: number

    radius: number

    count: number

    constructor(color: string, x: number, y: number, circleSize: number, radius: number) {
      this.color = color
      this.x = 0
      this.y = 0
      this.posX = x
      this.posY = y

      this.circleSize = circleSize

      this.direction = getRandom(-1, 1)
      this.amplitude = getRandom(-50, 50)
      this.waveSpeed = getRandom(2, 3)
      this.speed = getRandom(1, 2)

      particles[pIndex] = this
      this.id = pIndex
      pIndex += 1

      this.radius = radius
      this.count = 0
    }

    draw() {
      this.radius += Math.sin(this.count * 0.1)

      this.wave =
        Math.sin((Math.PI / 180) * this.count * this.waveSpeed * this.direction) * this.amplitude

      this.x =
        Math.sin((Math.PI / 180) * this.count * this.speed * this.direction) * this.circleSize -
        this.radius / 2 +
        this.wave +
        this.posX
      this.y =
        Math.cos((Math.PI / 180) * this.count * this.speed * this.direction) * this.circleSize -
        this.radius / 2 +
        this.wave +
        this.posY

      this.count += 1

      const colA = hex2rgba(this.color, 1)
      const colB = hex2rgba(this.color, 0)

      const grd = ctx.createRadialGradient(
        this.x + this.radius / 2,
        this.y + this.radius / 2,
        0,
        this.x + this.radius / 2,
        this.y + this.radius / 2,
        this.radius / 2
      )
      grd.addColorStop(0, colA)
      grd.addColorStop(1, colB)

      ctx.fillStyle = grd
      ctx.beginPath()
      ctx.fillRect(this.x, this.y, this.radius, this.radius)
      ctx.closePath()
      ctx.fill()
    }
  }

  const colorTips = ['#F13832', '#FD6F3E', '#47E892', '#41D6E5', '#FE2FA6']

  for (let i = 0; i < 30; i += 1) {
    const r = Math.floor(getRandom(0, colorTips.length))

    // eslint-disable-next-line no-new
    new Particle(
      colorTips[r],
      getRandom(0, canvas.width / 2),
      getRandom(0, canvas.height),
      getRandom(-canvas.width, canvas.width),
      getRandom(canvas.width / 2, canvas.width)
    )
  }

  function loop() {
    stats.begin()

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    // eslint-disable-next-line no-restricted-syntax
    for (const particle of particles) {
      particle.draw()
    }

    stats.end()

    requestAnimationFrame(loop)
  }

  loop()

  // window.addEventListener('resize', () => {
  //   canvas.width = window.innerWidth / 2
  //   canvas.height = window.innerHeight / 2
  // })
}

export default animateScene
