import { useState } from 'react'
import constate from 'constate'

const useStore = () => {
  const useDummy = useState<boolean>()

  return { useDummy }
}

const [StoreProvider, useStoreContext] = constate(useStore)

export { StoreProvider, useStoreContext }
