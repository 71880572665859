import { useMediaQuery } from 'react-responsive'
import { InlineWidget } from 'react-calendly'
import styled from 'styled-components/macro'
import MailIcon from '@mui/icons-material/Mail'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import Diversity2Icon from '@mui/icons-material/Diversity2'

import { TitleBackground, Section, FlexCol, Col, ContactLink } from '../../layout/Elements'

const CTAContactLink = styled(ContactLink)`
  font-size: ${props => (props.isMobile ? '1.5rem' : '2rem')};
  border-image: linear-gradient(
    to left,
    #b827fc 0%,
    #2c90fc 25%,
    #b8fd33 50%,
    #fec837 75%,
    #fd1892 100%
  );
  border-image-slice: 1;
  border-bottom-width: 0.5rem;

  &:hover,
  &:focus,
  &:focus-within {
    border-width: 0.5rem;
  }
`

export default function Contact() {
  const isLargeScreen = useMediaQuery({ minWidth: 1100 })
  const isMobile = useMediaQuery({ maxWidth: 650 })

  return (
    <Section isLargeScreen={isLargeScreen} isMobile={isMobile}>
      <h1 id="contact">Contact</h1>
      <TitleBackground>Contact</TitleBackground>

      <FlexCol
        isLargeScreen={isLargeScreen}
        style={{ gap: '4rem', flexDirection: isLargeScreen ? 'row' : 'column-reverse' }}
      >
        <Col style={{ flex: '1 0' }}>
          <ContactLink href="mailto:hello@designhorizon.io">
            <MailIcon fontSize={isMobile ? 'medium' : 'large'} htmlColor="#151515" />
            hello@designhorizon.io
          </ContactLink>
          <ContactLink href="tel:+33678092083">
            <LocalPhoneIcon fontSize={isMobile ? 'medium' : 'large'} htmlColor="#151515" />
            +33678092083
          </ContactLink>
          <ContactLink
            href="https://www.linkedin.com/company/design-horizon-development"
            target="_blank"
          >
            <LinkedInIcon fontSize={isMobile ? 'medium' : 'large'} htmlColor="#151515" />
            LinkedIn
          </ContactLink>
          <ContactLink href="https://www.crealead.com/annuaire-entrepreneurs/design-horizon-development">
            <Diversity2Icon fontSize={isMobile ? 'medium' : 'large'} htmlColor="#151515" />
            Coopérative Crealead
          </ContactLink>
        </Col>

        <Col style={{ flex: '1 0' }}>
          <h4>
            ✔ Vous êtes entrepreneur, porteur de projet innovant et recherchez une méthode efficace
            et rapide pour convaincre des investisseurs et concrétiser votre projet
          </h4>
          <h4>
            ✔ Vous recherchez un accompagnement pour vos équipes de développeurs et souhaitez
            instaurer une méthode de développement efficace à long terme
          </h4>
        </Col>
      </FlexCol>

      <CTAContactLink href="https://www.malt.fr/profile/quentinfremeaux">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Malt"
          role="img"
          width="auto"
          height={isMobile ? '3rem' : '4rem'}
          viewBox="0 0 512 512"
        >
          <path
            fill="#FC5656"
            d="m408.4 103.8c-32.5-32.4-67.1-11.4-88.8 10.2L114.8 318.8c-21.7 21.7-44.4 54.7-10.2 88.8c34.1 34.1 67 11.4 88.7-10.3l204.8-204.8c21.7-21.6 42.7-56.3 10.3-88.7zm-195.7-8.4 43.4 43.4 44.1-44.2c3-3 6-5.8 9.1-8.4c-4.6-23.3-17.9-44.4-53.3-44.4c-35.4 0-48.7 21.2-53.2 44.5c3.3 2.9 6.6 5.8 9.9 9.1zm87.5 322.1-44.1-44.1-43.4 43.3c-3.3 3.3-6.5 6.4-9.8 9.2c5 23.8 19 45.5 53.1 45.5c34.2 0 48.3-21.9 53.2-45.7c-3-2.6-6-5.2-9-8.2zm-105.9-217h-83.6c-30.7 0-70 9.7-70 55.5c0 34.3 21.9 48.3 45.8 53.2c2.8-3.2 107.8-108.7 107.8-108.7zm231.5 2.3c-2.6 3-107.9 108.8-107.9 108.8h82.4c30.7 0 70-7.3 70-55.6c0-35.3-21.1-48.6-44.5-53.2zm-204.1-29.7 14.9-14.9-43.3-43.4c-21.7-21.7-54.6-44.4-88.8-10.2c-25 25-19.4 49.4-6.2 69.1c4.1-.3 123.4-.6 123.4-.6zm68.7 165.9-15 15 44.2 44.1c21.7 21.7 56.3 42.7 88.7 10.3c24.2-24.2 18.7-49.7 5.3-70c-4.3.3-123.2.6-123.2.6z"
          />
        </svg>
        M&apos;embaucher sur MALT
      </CTAContactLink>

      <InlineWidget
        url="https://calendly.com/designhorizon-quentinfremeaux"
        styles={{ width: '100%', height: '800px' }}
      />
    </Section>
  )
}
