/* eslint-disable react/no-unescaped-entities */

function MentionsLegales() {
  return (
    <section style={{ margin: '0 auto', padding: '10rem 1rem 5rem', maxWidth: '800px' }}>
      <h2>Informations légales</h2>
      <p style={{ textAlign: 'center' }}>MENTIONS LEGALES :</p>
      <p style={{ textAlign: 'justify' }}>
        En conformité aux directives des articles 6-III et 19 de la Loi numero 2004-575 du 21 juin
        2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., nous informons les
        utilisateurs du site internet :{' '}
        <a href="https://designhorizon.io" target="_blank" rel="noreferrer">
          designhorizon.io
        </a>{' '}
        Ces infos qui suivent :
      </p>
      <br />
      <p style={{ textAlign: 'justify' }}>
        <strong>1. Informations légales :</strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        Le site web{' '}
        <a
          href="https://designhorizon.io"
          style={{ color: 'rgb(7, 130, 193)', fontFamily: 'sans-serif, Arial, Verdana' }}
          target="_blank"
          rel="noreferrer"
        >
          designhorizon.io
        </a>{' '}
        est la propriété absolue de <strong>Design Horizon & Development</strong>, chez{' '}
        <strong>SA SCOP SCAE à capital variable</strong> <strong>Crealead</strong>.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>SA SCOP SCAE à capital variable</strong> <strong>Crealead</strong> - Num. de
        Téléphone : <strong>04 67 06 96 72</strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>Hôtel de la coopération - 55, rue Saint-Cléophas</strong>{' '}
        <strong>34070 Montpellier</strong>
        <br />
        Immatriculée au RCS (Registre du Commerce et des Sociétés) au numéro{' '}
        <strong>438 076 200 000 23</strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        N° TVA : <strong>FR56438076200</strong>
        <br />
        Mail :{' '}
        <strong>
          <a href="mailto:contact@crealead.com?subject=Contact a partir des mentions légales via le site designhorizon.io">
            contact@crealead.com
          </a>
        </strong>
        <br />
        <br />
        Le Fondateur du site suivant : <strong>Frémeaux Quentin</strong>
        <br />
        Chef de la publication : <strong>Frémeaux Quentin</strong>
        <br />
        De quelle façon entrer en contact avec le Décideur de la publication :{' '}
        <strong>
          <a href="mailto:hello@designhorizon.io?subject=Contact a partir des mentions légales via le site designhorizon.io">
            hello@designhorizon.io
          </a>
        </strong>
      </p>
      <br />
      Le Réalisateur est : <strong>Frémeaux Quentin</strong>
      <br />
      Comment entrer en contact avec le Concepteur :{' '}
      <strong>
        <a href="mailto:hello@designhorizon.io?subject=Contact a partir des mentions légales via le site designhorizon.io">
          hello@designhorizon.io
        </a>
      </strong>
      <br />
      <br />
      L'hebergeur du site est : OVH
      <br />
      SAS
      <br />
      Registre du commerce et des sociétés (RCS) Lille Métropole 424 761 419 00045
      <br />
      Code APE 2620Z
      <br />
      Num. de TVA : FR 22 424 761 419
      <br />
      Adresse du siège : 2 rue Kellermann - 59100 Roubaix - France
      <br />
      <br />
      <br />
      Les mentions ont été réalisées via{' '}
      <strong>
        <a href="https://www.generateur-de-mentions-legales.com" target="_blank" rel="noreferrer">
          le générateur de mentions légales
        </a>
      </strong>
      <br />
      <p style={{ textAlign: 'justify' }} />
      <p style={{ textAlign: 'justify' }} />
      <br />
      <p style={{ textAlign: 'justify' }}>
        <strong>2. Présentation :</strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        Tout internaute qui utilise le site :{' '}
        <a href="https://designhorizon.io" target="_blank" rel="noreferrer">
          designhorizon.io
        </a>{' '}
        est considéré comme utilisateur du site suivant.
        <br />
        Le site web <strong>designhorizon.io</strong> regroupe plusieurs services, en l'état, mis à
        la disposition des usageé. Il est précisé que ceux-ci doivent rester courtois et faire
        preuve de bonne foi tant envers les autres utilisateurs qu'envers le Réalisateur du site
        designhorizon.io. Le site designhorizon.io est actualisé régulièrement par Frémeaux Quentin.
        <br />
        Frémeaux Quentin s'efforce d'indiqué sur le site suivant designhorizon.io des informations
        les plus réalistes possibles (sous réserve de modifications apportées depuis leur mise en
        ligne), mais ne peuvent affirmer l'exactitude, la complétude et les informations diffusées
        sur son site web, qu'elles soient de son fait ou non. En dépendance, l'utilisateur reconnaît
        se servir des informations données sous sa responsabilité exclusive.
      </p>
      <p style={{ textAlign: 'justify' }} />
      <br />
      <p style={{ textAlign: 'justify' }}>
        <strong>3. Accessibilité :</strong>
        <br />
        <br />
        Le site designhorizon.io est par principe accessible aux utilisateurs 24/24h, 7/7j, sauf en
        cas de maintenance, prévu ou non, pour les besoins de mise à jour ou en cas de force
        majeure. En cas d'impossibilité d'accès au service, designhorizon.io s'engage à faire de son
        mieux pour rétablir l'accès au site et s'efforcera alors de communiquer préalablement aux
        utilisateurs les dates et heures de l'interruption. N'étant asservi qu'à une redevance de
        moyen, designhorizon.io ne pourrait être designé pour responsable des soucis, quelle qu'en
        soit sa nature, résultant d'une indisponibilité du service
      </p>
      <p style={{ textAlign: 'justify' }} />
      <br />
      <p style={{ textAlign: 'justify' }}>
        <strong>4. Droit de propriété intellectuelle :</strong>
        <br />
        <br />
        Frémeaux Quentin est le propriétaire unique de l'intégralité des droits de propriété
        intellectuelle ou détient les droits et autorisations d'usage sur l'intégralité des éléments
        accessibles sur le site, tant structurellement que sur les textes, images, graphismes, logo,
        sons, logiciels…
        <br />
        Toute reproduction totale ou partielle du site suivant designhorizon.io, représentation,
        edition, mis à jour totalement ou partiellement de l'un quelconque de ces éléments, sans
        tenir compte du moyen ou le procédé utilisé, est strictementinterdite, excepté autorisation
        préalable de Frémeaux Quentin, propriétaire du site web à l'email : hello@designhorizon.io,
        à défaut elle sera considérée comme constitutive d'une contrefaçon et passible de poursuite
        conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété
        Intellectuelle.
      </p>
      <p>
        Toute reproduction site internet designhorizon.io, représentation, modification, adaptation
        totalement ou partiellement de l'un quelconque de ces éléments, sans tenir compte du moyen
        ou la technique utilisé, est formellementinterdite, excepté autorisation écrite préalable de
        Frémeaux Quentin, responsable du site web à l'email : hello@designhorizon.io, à défaut elle
        sera désigné de la même manière qu'une contrefaçon étant passible de poursuite d'après les
        dispositions des multiples articles L.335-2 et suivants du Code de Propriété Intellectuelle.
      </p>
      <p style={{ textAlign: 'justify' }}>
        <br />
        <strong>5. Liens hypertextes et cookies :</strong>
        <br />
        <br />
        Le site designhorizon.io contient un certain nombre de liens hypertextes vers plusieurs
        sites web (partenariats, informations etc) mis en place suite à l'accord de Frémeaux
        Quentin. Néanmoins, Frémeaux Quentin ne peut pas vérifier l'ensemble des élements des
        différents sites ainsi consultés et rejette donc l'entière responsabilité de ce fait quand
        aux risques éventuels de contenus illicites.
        <br />
        L'usager est donc tenu informé que au cours de ses navigation sur le site web
        designhorizon.io, un ou plusieurs cookies peuvent s'installer de façon automatique sur son
        pc grâce à son logiciel informatique de navigation. Un cookie c'est un assemblage de données
        informatique qui ne permettra pas de savoir qui est l'utilisateur, cependant qui sauvegarde
        des indications concernant la navigation de l'utilisateur sur le site interne. <br />
        Le paramétrage du logiciel de navigation permet de renseigner de la compagnie de cookie et
        potentiellement, de pouvoir la contester de la procédure expliquée à l' adresse :{' '}
        <a href="https://www.designhorizon.io">www.designhorizon.io</a>. La mise en place peut
        néanmoins de paramétrer le navigateur de son ordinateur de façon à refuser la mise en place
        des cookies, étant conscient que le rebut de la mise en place d'un cookie peut
        potentiellement entraîner l'incapacité d'avoir accès à divers secteurs. Pour tout blocage
        des cookies, recherchez dans le moteur de recherche : blocage des cookies et suivez les
        consignes en lien avec votre navigateur.
      </p>
      <p>
        <strong>6. Protection des personnes et biens - Gestion des données personnelles :</strong>
        <br />
        <br />
        En France, les données personnelles sont par exemple protégées par la loi n° 78-87 du 6
        janvier 1978 ainsi que la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal
        et la Directive Européenne datant du 24 octobre 1995.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Sur le site suivant designhorizon.io, Frémeaux Quentin ne aucune information personnelles (
        suivant l'article 4 loi n°78-17 du 06 janvier 1978) relatives à l'usager que pour la
        nécéssité de différents services mis à disposition par le site web designhorizon.io.
        L'utilisateur donne ces données en toute connaissance de cause, particulièrement lorsqu'il
        effectue par lui-même à leur saisie. Il est alors détaillé à l'utilisateur du site interne
        designhorizon.io le devoir ou pas de rentrer ces informations.
        <br />
        Conformément aux dispositions des articles 38 et autres de la loi 78-17 du 6 janvier 1978
        relative à l'informatique, aux fichiers et aux différentes libertés, tout utilisateur
        dispose d'un droit d'accès , de rectification, de suppression et d'opposition à toutes les
        données personnelles le concernant. Pour l'exercer, adressez une requête à designhorizon.io
        par mail :{' '}
        <strong>
          <a href="mailto:hello@designhorizon.io?subject=Contact à partir des mentions légales via le site designhorizon.io">
            hello@designhorizon.io
          </a>
        </strong>{' '}
        ou par lettre manuscrite signée, accompagnée d'une copie de pièce d'identité ainsi qu'une
        signature du propriétaire du document, en mentionnant l'adresse à laquelle une réponse doit
        être retournée.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Sans information personnelle de l'utilisateur du site interne designhorizon.io ne sera
        publiée à l'insu de l'utilisateur, échangée, transférée, vendue sur un support quelconque à
        des groupes. Seulement l'hypothèse de vente du site designhorizon.io et de tout ses droits
        autorise Frémeaux Quentin à joindre les informations à l' acquéreur qui serait donc à son
        tour tenu à la même obligation de conservation et de modification des données concernant
        l'utilisateur du site web designhorizon.io.
        <br />
        Le site web designhorizon.io est en conformité avec le RGPD voir notre politique RGPD.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Les bases de données sont protégées par les obligations de la loi du 1er juillet 1998
        transposant ainsi la directive 96/9 du 11 mars 1996 relative à la protection juridique des
        bases de données(BDD).
      </p>
    </section>
  )
}

export default MentionsLegales
