import { Swiper, SwiperSlide } from 'swiper/react'
// @ts-expect-error no exported types
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components/macro'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import { TitleBackground, Section, Inline } from '../../layout/Elements'

export const SlideItem = styled.div`
  height: 100%;
  width: 100%;
  background: #00000010;
  color: #171717;
  padding: 2rem;

  border-radius: 1rem;
  backdrop-filter: blur(40px);
  box-shadow: 0px 1.6733156776526186px 1.6733156776526186px 0px rgba(0, 0, 0, 0),
    0px 3.984206390969505px 3.984206390969505px 0px rgba(0, 0, 0, 0.00996),
    0px 7.259321882820145px 7.259321882820145px 0px rgba(0, 0, 0, 0.01815),
    0px 12.068385491961365px 12.068385491961365px 0px rgba(0, 0, 0, 0.03017),
    0px 19.50049274209897px 19.50049274209897px 0px rgba(0, 0, 0, 0.04875),
    0px 31.901418213351462px 31.901418213351462px 0px rgba(0, 0, 0, 0.07975),
    0px 54.96035212570371px 54.96035212570371px 0px rgba(0, 0, 0, 0.1374),
    0px 50px 50px 0px rgba(0, 0, 0, 0.25);

  /* &:hover {
    box-shadow: 0px 1.6733156776526186px 1.6733156776526186px 0px rgba(0, 0, 0, 0),
      0px 3.984206390969505px 3.984206390969505px 0px rgba(0, 0, 0, 0.00996),
      0px 7.259321882820145px 7.259321882820145px 0px rgba(0, 0, 0, 0.01815),
      0px 12.068385491961365px 12.068385491961365px 0px rgba(0, 0, 0, 0.03017),
      0px 19.50049274209897px 19.50049274209897px 0px rgba(0, 0, 0, 0.04875),
      0px 31.901418213351462px 31.901418213351462px 0px rgba(0, 0, 0, 0.07975),
      0px 54.96035212570371px 54.96035212570371px 0px rgba(0, 0, 0, 0.1374),
      0px 60px 60px 0px rgba(0, 0, 0, 0.35);
  } */
`

export default function Realizations() {
  const isLargeScreen = useMediaQuery({ minWidth: 1100 })
  const isMobile = useMediaQuery({ maxWidth: 650 })

  return (
    <Section isLargeScreen={isLargeScreen} isMobile={isMobile}>
      <h1 id="realisations">Réalisations</h1>
      <TitleBackground>Réalisations</TitleBackground>

      <Swiper
        modules={[Autoplay, Pagination, Navigation]}
        spaceBetween={50}
        slidesPerView={isLargeScreen ? 2 : 1}
        centeredSlides
        navigation
        loop
        pagination={{ clickable: true }}
        // @ts-expect-error lib issue - autoPlay missing in types
        autoPlay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        style={{ width: '100%', overflow: 'visible' }}
      >
        <SwiperSlide>
          <SlideItem>
            <Inline>
              <img alt="" width="60px" src="./dalya-icon.png" />
              <h2 style={{ color: '#474596' }}>
                Dal-IA - Génération simplifiée de factures grace à l&apos;Intelligence Artificielle
              </h2>
            </Inline>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <img
                alt=""
                width="100%"
                style={{ maxWidth: '560px', borderRadius: '10px' }}
                height="auto"
                src="./dal-ia_exemple.png"
              />
            </div>
            <br />
            🤖 <strong>Intelligence Artificielle :</strong>
            <br />
            J&apos;ai pu accompagné Dalya dans l’intégration et la conception d&apos;une
            Intelligence Artificielle qui permet de générer des factures simplement avec une phrase.
            <br />
            <br />
            🧑‍🎨 <strong>Interface UI / UX :</strong>
            <br />
            Amélioration de l&apos;interface et du parcours utilisateurs. Reflexion UX / UI + prise
            en charge responsivité mobile.
            <br />
            <br />
            👨‍💻 <strong>Developpement Typescript et Architecture Hexagonale :</strong>
            <br />
            Compétence fullstack sur un monorepo avec technique de conception en DDD. Framework
            front Next.JS / React et back avec Prisma / Effect TS couplé à Supabase.
            <h3 style={{ textAlign: 'center' }}>
              <a
                href="https://dalya.app/dal-ia"
                target="_blank"
                rel="noreferrer"
                onClick={e => e.stopPropagation()}
              >
                Découvrir le projet
              </a>
            </h3>
          </SlideItem>
        </SwiperSlide>

        <SwiperSlide>
          <SlideItem>
            <Inline>
              <img alt="" width="60px" src="./crealead-icon.png" />
              <h2 style={{ color: '#c40d3c' }}>Crealead - Prototype du Portail Entrepreneurs</h2>
            </Inline>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <img
                alt=""
                width="100%"
                style={{ maxWidth: '560px', borderRadius: '10px' }}
                height="auto"
                src="./crealead-portail_prototype.png"
              />
            </div>
            <br />
            🧑‍🎨 <strong>Conception Maquettes du Portail des Entrepreneurs :</strong>
            <br />
            Conception de maquettes interactives pour le portail interne de la coopérative Crealead
            avec Framer. Ces prototypes ont permis des tests utilisateurs efficaces, guidant ainsi
            les ajustements avant développement. Ces maquettes ont également fourni un Design System
            solide pour les équipes de développement.
            <br />
            <br />
            🌍{' '}
            <strong>
              Co-entrepreneur en Coopérative, gestion du Pole Numérique et Animation :
            </strong>
            <br />
            Au sein de la coopérative Crealead, j&apos;accompagne les membres dans le secteur du
            Numérique en organisant des ateliers et des conférences pour démontrer l&apos;expertises
            de nos entrepreneurs dans le Numérique en Occitanie.
            <h3 style={{ textAlign: 'center' }}>
              <a
                href="https://thoughtful-sphere-229026.framer.app/"
                target="_blank"
                rel="noreferrer"
                onClick={e => e.stopPropagation()}
              >
                Découvrir le projet
              </a>
            </h3>
          </SlideItem>
        </SwiperSlide>

        <SwiperSlide>
          <SlideItem>
            <Inline>
              <img alt="" width="60px" src="./eon-icon.png" />
              <h2 style={{ color: '#2cc7de' }}>
                Eon - Application mobile communautaire pour agir sur son empreinte carbone
              </h2>
            </Inline>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <img
                alt=""
                width="100%"
                height="auto"
                src="./EonAppMobile.jpg"
                style={{ maxWidth: '560px', borderRadius: '25px' }}
              />
            </div>
            <br />
            🌍 <strong>Agir sur son empreinte carbone :</strong>
            <br />
            Afin d&apos;agir sur mon empreinte carbone de manière ludique, je me lance dans le
            développement d&apos;une application mobile sur le mode du Serious Game.
            <br />
            <br />
            👥 <strong>Appli communautaire :</strong>
            <br />
            Le but est de renseigner ses habitudes de vie et de voir évoluer une planète en 3D en
            fonction de son empreinte carbone issu de ces habitudes. Il est possible d&apos;agir sur
            certaines habitudes tels que Transport / Trajet, Maison, ou Nourriture, avec un système
            de carte d&apos;action pour faire évoluer positivement son empreinte carbone.
            <br />
            <br />
            📱 <strong>Design et Développement Mobile :</strong>
            <br />
            Un prototype a été réalisé sur Framer, et le développement mobile s&apos;est fait sur
            React Native avec le framework Expo et la modélisation 3D utilise ThreeJS.
            <h3 style={{ textAlign: 'center' }}>
              <a
                href="https://framer.com/projects/Eon-Mobile-Prototype--4rVHwzuOuRhp5qQBZ34R-588hy"
                target="_blank"
                rel="noreferrer"
                onClick={e => e.stopPropagation()}
              >
                Découvrir le projet
              </a>
            </h3>
          </SlideItem>
        </SwiperSlide>

        <SwiperSlide>
          <SlideItem>
            <Inline>
              <img alt="" width="60px" src="./shareadesk-icon.png" />
              <h2 style={{ color: '#00bd97' }}>
                Shareadesk - Partage de bureaux entre particuliers
              </h2>
            </Inline>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/CU_TiYivhqA"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{ borderRadius: '10px' }}
              />
            </div>
            <br />
            🚀 <strong>Projet d&apos;entreprenariat :</strong>
            <br />
            Notre vocation est de rendre le télétravail plus agréable et le co-working plus
            accessible. Sur le mode de AirBnb, Shareadesk c&apos;est une application de location de
            bureaux entre particuliers.
            <br />
            <br />
            💻 <strong>Prototype d&apos;application web :</strong>
            <br />
            Avec une organisation agile et la méthode Design Sprint, j&apos;ai acquis une solide
            expérience dans la conception d&apos;application et validation de concept rapide et à
            moindre coût. De la conception au déploiement avec un développement s&apos;appuyant sur
            des stacks techniques modernes, j&apos;ai pu développer une première application en
            moins de 6 mois.
            <br />
            <br />
            🏆 <strong>Concours Worknight 2022 :</strong>
            <br />
            Nous remportons la première place dans la Catégorie Aménageur, lors du Concours
            Worknight 2022.
            <h3 style={{ textAlign: 'center' }}>
              <a
                href="https://www.francebleu.fr/infos/societe/la-nouvelle-eco-shareadesk-une-plateforme-montpellieraine-pour-teletravailler-chez-des-particuliers-1644958745"
                target="_blank"
                rel="noreferrer"
                onClick={e => e.stopPropagation()}
              >
                Découvrir le projet
              </a>
            </h3>
          </SlideItem>
        </SwiperSlide>
      </Swiper>
    </Section>
  )
}
