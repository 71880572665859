import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components/macro'

import { Background, Canvas, Arrow } from '../../layout/Elements'
import animateScene from './animateScene'
import Realizations from './Realizations'
import Services from './Services'
import Contact from './Contact'
import About from './About'

const Text = styled.p<{ isLargeScreen: boolean; isMobile: boolean }>`
  text-align: left;
  margin: auto;
  margin-left: ${props => (props.isLargeScreen ? '7rem' : '3rem')};
  width: ${props => (props.isMobile ? '80%' : '50%')};
  height: 100%;
  min-height: 500px;
  letter-spacing: 0px;
  font-family: 'Manrope', sans-serif;
  font-weight: 800;
  font-size: ${props => (props.isMobile ? '6vw' : '4vw')};
  line-height: 1.2em;
  color: #fff;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
`
const Subtext = styled.p<{ isMobile: boolean }>`
  letter-spacing: 0px;
  font-family: 'Manrope', sans-serif;
  font-weight: 800;
  font-size: ${props => (props.isMobile ? '4.8vw' : '3.2vw')};
  line-height: 1.2em;
`
const TextContainer = styled.div`
  padding-top: 7rem;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

function Landing() {
  const [isAnimated, setIsAnimated] = useState(false)

  const isLargeScreen = useMediaQuery({ minWidth: 1100 })
  const isMobile = useMediaQuery({ maxWidth: 600 })

  useEffect(() => {
    if (!isAnimated) {
      animateScene()
      setIsAnimated(true)
    }
  }, [isAnimated])

  return (
    <>
      <Background id="top">
        <Canvas id="backgroundCanvasColors" />
        <TextContainer>
          <Text isLargeScreen={isLargeScreen} isMobile={isMobile}>
            Pour la création de vos projets numériques innovants de A à Z.{' '}
            <Subtext isMobile={isMobile}>Développement web et mobile.</Subtext>
          </Text>

          <Arrow href="#services">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="129" viewBox="0 0 60 129">
              <path
                d="M 26.887 -1.377 L 26.887 119.644 L -1.132 88.613 L -1.132 97.922 L 30 128.953 L 61.132 97.922 L 61.132 88.613 L 33.113 119.644 L 33.113 -1.377"
                fill="rgb(255,255,255)"
              />
            </svg>
          </Arrow>
        </TextContainer>
      </Background>

      <Services />

      <Realizations />

      <About />

      <Contact />
    </>
  )
}

export default Landing
