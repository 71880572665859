import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

const updateDocumentHeaders = (lang: string, t: TFunction) => {
  document.documentElement.setAttribute('lang', lang)
  document.title = t("Design Horizon - Développement d'application web et mobile.")
  document
    .querySelector('meta[name="description"]')
    ?.setAttribute(
      'content',
      t('Pour la création de votre projet numérique innovant de A à Z : application web et mobile.')
    )

  // OpenGraph
  document.querySelector('meta[property="og:locale"]')?.setAttribute('content', lang)
  document
    .querySelector('meta[property="og:title"]')
    ?.setAttribute('content', t("Design Horizon - Développement d'application web et mobile."))
  document
    .querySelector('meta[property="og:description"]')
    ?.setAttribute(
      'content',
      t('Pour la création de votre projet numérique innovant de A à Z : application web et mobile.')
    )

  // Twitter
  document
    .querySelector('meta[name="twitter:title"]')
    ?.setAttribute('content', t("Design Horizon - Développement d'application web et mobile."))
  document
    .querySelector('meta[name="twitter:description"]')
    ?.setAttribute(
      'content',
      t('Pour la création de votre projet numérique innovant de A à Z : application web et mobile.')
    )
  document
    .querySelector('meta[name="twitter:image:alt"]')
    ?.setAttribute('content', 'Design Horizon')
}

const useInternationalization = () => {
  const [init, setInit] = useState(false)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    updateDocumentHeaders(i18n.language, t)

    setInit(true)
  }, [t, i18n.language])

  return { internationalizationInit: init }
}

export default useInternationalization
